<template>
  <UserGroupListComponent/>
</template>

<script>
import UserGroupListComponent from '@/components/UserGroupListComponent.vue';

export default {
  name: 'UserGroupView',
  components: {
    UserGroupListComponent,
  },
  props: [],
  mounted() {

  },
};
</script>
