<template>
  <div class="userGroupList">
    <div class="page-title">{{ $t('pageTitle.usergroups') }}</div>
    <div class="actionBar">
      <div class="actionButton bgBrown refresh" @click="loadUserGroups"><i class="fas fa-sync"></i>
        <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
      <div class="listWrapper">
        <EmptyListAnimationComponent v-if="userGroups != null && userGroups.length == 0"/>
        <div class="listItemWrapper" v-for="entry in userGroups" :key="entry._id">
          <div class="listItem userGroup" @click="editUserGroup(entry)"><div class="icon"><i class="fas fa-users"/></div> {{entry.name}}</div>
          <div class="listActionWrapper">
            <span class="listAction userGroupAction" @click="editUserGroup(entry)"><i class="fas fa-pen"/></span>
            <span class="listAction userGroupAction" @click="deleteUserGroup(entry._id)"><i class="fas fa-trash"/></span>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-add-button" @click="addUserGroup">
      <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
    </div>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDelete')"/>
    <CreateOrEditUserGroupModal :userGroupToEdit="userGroupToEdit" ref="createOrEditUserGroupModal" @refresh="loadUserGroups"/>
    <!--PromptModal ref="newGroupNameModal" :title="$t('modal.titleUserGroupName')" :text="$t('modal.textUserGroupName')"/-->

  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
// import PromptModal from '@/components/modals/PromptModal.vue';
import CreateOrEditUserGroupModal from '@/components/modals/CreateOrEditUserGroupModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import EmptyListAnimationComponent from '@/components/EmptyListAnimationComponent.vue';

export default {
  name: 'UserGroupListComponent',
  props: [],
  mixins: [ScreenHeightMixin],
  components: {
    ConfirmModal,
    CreateOrEditUserGroupModal,
    EmptyListAnimationComponent,
  },
  data() {
    return {
      userGroups: [],
      userGroupToEdit: null,
    };
  },
  watch: {
  },
  mounted() {
    this.loadUserGroups();
  },
  methods: {
    async loadUserGroups() {
      this.userGroups = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend('userGroups');
        data.payload.forEach((entry) => {
          if (entry.name != null) {
            const e = entry;
            e.name = CryptoHelper.decrypt(e.name).toString('utf-8');
          }
        });
        this.userGroups = data.payload;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async addUserGroup() {
      // try {
      this.userGroupToEdit = { };
      await this.$refs.createOrEditUserGroupModal.show();
      //   if (newUserGroupName != null && newUserGroupName.trim().length > 0) {
      //     const encUserGroupName = CryptoHelper.encrypt(newUserGroupName);
      //     await ServiceHelper.sendDataToBackend('userGroup', { name: encUserGroupName });
      //     this.loadUserGroups();
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      return null;
    },
    async deleteUserGroup(userGroupId) {
      if (await this.$refs.confirmDeleteModal.show()) {
        try {
          const url = `userGroup/${userGroupId}`;
          await ServiceHelper.deleteDataFromBackend(url);
          this.loadUserGroups();
        } catch (error) {
          console.error(error);
        }
      }
      return null;
    },
    async editUserGroup(userGroup) {
      // try {
      this.userGroupToEdit = JSON.parse(JSON.stringify(userGroup));
      this.$refs.createOrEditUserGroupModal.show();
      //   if (newUserGroupName != null && newUserGroupName.trim().length > 0) {
      //     const url = `userGroup/${userGroup._id}`;
      //     const encUserGroupName = CryptoHelper.encrypt(newUserGroupName);
      //     await ServiceHelper.sendDataToBackend(url, { name: encUserGroupName }, 'PUT');
      //     this.loadUserGroups();
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      return null;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.listWrapper .listItemWrapper:nth-child(odd) {
  background-color: var(--color_list_1);
}
.listWrapper .listItemWrapper:nth-child(even) {
  background-color: var(--color_list_2);
}

</style>
