<template>
  <CustomModal v-model="showUserGroupModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{userGroup != null && userGroup._id != null ? $t('modal.titleEditUserGroup') : $t('modal.titleCreateUserGroup')}}</template>
    <div class="form" v-if="userGroup != null && usersById != null">
      <div class="row" >
        <div class="label" >{{ $t('modal.labelName') }}:</div>
        <div class="value"><input @keyup.enter="confirm" ref="userGroupName" v-model="userGroup.name" type="text"/></div>
      </div>
      <div class="row">
        <div class="label">{{ $t('modal.labelUsers') }}</div>
        <div class="value">
          <div class='tagsInput'>
            <div class="tagSelection" v-if="showUserOptions && proposedUsers.length > 0">
              <div class="tagOption" @click="addUser(u)" v-for="u in proposedUsers" :key="u._id">{{u.firstname}} {{u.lastname}} ({{u.email}})</div>
            </div>
            <div class="tag" v-for="u in chosenUsers" :key="u">{{getUserName(u)}} <div @click="removeUser(u)" class="delete">x</div> </div>
            <input v-model="userQuery" @keyup="proposeUsers" type="text" :placeholder="$t('modal.labelUserName')"/>
          </div>
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';

export default {
  name: 'CreateOrEditUserGroupModal',
  props: ['userGroupToEdit'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showUserGroupModal: false,
      userGroup: null,
      users: [],
      usersById: null,
      userQuery: '',
      proposedUsers: [],
      chosenUsers: [],
      showUserOptions: true,
    };
  },
  watch: {
    userGroupToEdit(newVal) {
      // console.log('userGroup userGroupToEdit', this.userGroup);
      this.userGroup = newVal;
      this.fetchUsers();
    },
  },
  mounted() {
    this.userGroup = this.userGroupToEdit;
    this.fetchUsers();
  },
  methods: {
    handleRightChange(accessRight, type) {
      const ar = accessRight;
      ar.type = type;
      ar.read = type === 'READ';
      ar.write = type === 'WRITE';
    },
    async show() {
      await this.fetchUsers();
      this.showUserGroupModal = true;
    },
    hide() {
      this.showUserGroupModal = false;
    },
    async confirm() {
      if (this.userGroup.name == null || this.userGroup.name.trim() === '') {
        return;
      }
      // encrypt and send user
      try {
        this.userGroup.name = CryptoHelper.encrypt(this.userGroup.name);
        let ugId = this.userGroup._id;
        if (this.userGroup._id != null) {
          // update
          await ServiceHelper.sendDataToBackend(`userGroup/${this.userGroup._id}`, this.userGroup, 'PUT');
        } else {
          // create
          const url = this.parentUserGroup != null ? `userGroup/${this.parentUserGroup._id}` : 'userGroup';
          ugId = (await ServiceHelper.sendDataToBackend(url, this.userGroup)).payload._id;
        }
        await ServiceHelper.sendDataToBackend(`userGroupUsers/${ugId}`, this.chosenUsers.map((user) => user._id), 'PUT');
        this.$emit('refresh');
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    getUserName(u) {
      if (this.usersById != null && this.usersById[u._id] != null) {
        return `${this.usersById[u._id].firstname} ${this.usersById[u._id].lastname}`;
      }
      return 'unknown';
    },
    cancel() {
      this.hide();
    },
    async fetchUsers() {
      const data = await ServiceHelper.loadDataFromBackend('users');
      const usersById = {};
      this.chosenUsers = [];
      data.payload.forEach((entry) => {
        if (entry.firstname != null || entry.lastname != null) {
          const e = entry;
          e.firstname = CryptoHelper.decrypt(e.firstname).toString('utf-8');
          e.lastname = CryptoHelper.decrypt(e.lastname).toString('utf-8');
          e.email = CryptoHelper.decrypt(e.email).toString('utf-8');
          usersById[e._id] = e;
        }
      });
      this.users = data.payload;
      this.usersById = usersById;
      this.autoFocus();
      this.calculateChosen();
      return null;
    },
    calculateChosen() {
      this.chosenUsers = [];
      if (this.userGroup) {
        this.users.forEach((user) => {
          if (user.userGroups.includes(this.userGroup._id)) {
            this.chosenUsers.push(this.usersById[user._id]);
          }
        });
      }
    },
    proposeUsers() {
      this.proposedUsers = [];
      if (this.userQuery.trim().length === 0) {
        return;
      }
      this.users.forEach((user) => {
        if (!this.chosenUsers.includes(user) && `${user.firstname} ${user.lastname} ${user.email}`.toLowerCase().indexOf(this.userQuery.toLowerCase()) > -1) {
          this.proposedUsers.push(this.usersById[user._id]);
        }
      });
    },
    autoFocus() {
      if (!this.$refs.userGroupName) {
        setTimeout(this.autoFocus, 200);
      } else {
        this.$refs.userGroupName.focus();
      }
    },
    addUser(user) {
      this.chosenUsers.push(user);
      this.userQuery = '';
      this.proposeUsers();
    },
    removeUser(user) {
      this.chosenUsers = this.chosenUsers.filter((u) => u._id !== user._id);
      this.proposeUsers();
    },
  },
};
</script>

<style scoped>

::v-deep(.modal_content .form .row .tagsInput) {
  width: 100%;
  display: inline-block;
  background-color: white;
  border-bottom: 1px solid lightgray;
  position: relative;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection) {
  position: absolute;
  max-width: 100%;
  width: 100%;
  top: 0px;
  transform: translateY(-100%);
  max-height: 150px;
  overflow-y: auto;
  z-index: 9999;
  border: 1px solid gray;
  padding: 2px;
  box-shadow: 4px 4px 8px gray;
  background-color: rgba(255,255,255,0.8);
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption) {
  cursor: pointer;
  padding: 4px;
  transition: all ease-in 100ms;
}

::v-deep(.modal_content .form .row .tagsInput .tagSelection .tagOption:hover) {
  background-color: lightsalmon;
}

::v-deep(.modal_content .form .row .tagsInput input) {
  width: 80px;
  border: 0;
}

::v-deep(.modal_content .form .row .tagsInput .tag) {
  font-size: 12px;
  border-radius: 12px;
  line-height: 16px;
  background-color: var(--color_dark_green);
  padding: 4px 24px 4px 8px;
  color: white;
  margin-bottom: 4px;
  margin-right: 4px;
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

::v-deep(.modal_content .form .row .tagsInput .tag .delete) {
  position: absolute;
  line-height: 16px;
  right: 8px;
  top: 3px;
  font-size: 18px;
  cursor: pointer;
}
::v-deep(.modal_content .form .row .tagsInput .tag .delete:hover) {
  font-weight: 800;
}

::v-deep(.modal_content .form .row .label) {
  vertical-align: top;
}

::v-deep(.modal_content .form .row .fieldError) {
  color: var(--color_red);
  font-size: 12px;
}

::v-deep(.modal_content .form .row) {
  position: relative;
}

</style>
